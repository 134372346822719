import React from "react";
import Navbar from "../Components/Navbar";

function Header_Main() {
  return (
    <>
      <Navbar />
    </>
  );
}

export default Header_Main;
