import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const WhatsAppButton = () => {
  const whatsappLink = "https://wa.me/1234567890"; // Replace with your WhatsApp link

  return (
    <a
      href="
    https://api.whatsapp.com/send?phone=9011028878
    &amp;text=Hi!"
      data-action="share/whatsapp/share"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: "fixed",
        bottom: "30px",
        right: "30px",
        backgroundColor: "#25D366",
        color: "#fff",
        borderRadius: "50%",
        width: "60px",
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        cursor: "pointer",
        textDecoration: "none",
      }}>
      <WhatsAppIcon style={{ fontSize: "30px" }} />
    </a>
  );
};

export default WhatsAppButton;
