import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Logo from "../Assets/new_logo.png";

function NavBar({ toggleDrawer, state, categoryData, handleNavigation }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { pathname } = useLocation();
  const open = Boolean(anchorEl);

  // Handle Dropdown Menu Open/Close
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  // Drawer Close Utility on Route Change
  const isActive = (path) => pathname === path;

  return (
    <>
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "250px",
          },
        }}>
        <List>
          {/* Drawer Close Icon */}
          <IconButton
            sx={{ float: "right", paddingRight: "15px" }}
            onClick={toggleDrawer("right", false)}>
            <CloseIcon sx={{ color: "#8cb13d", fontSize: "35px" }} />
          </IconButton>
          <Divider />

          {/* Logo Section */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "40px 0",
            }}>
            <Link to="/" onClick={() => toggleDrawer("right", false)}>
              <img src={Logo} alt="logo" width="120px" />
            </Link>
          </div>

          {/* Navigation Links */}
          <ListItem>
            <ListItemButton>
              <Link
                to="/"
                className={`${isActive("/") ? "active1" : ""}`}
                style={{ color: "black", textDecoration: "none" }}
                onClick={() => toggleDrawer("right", false)}>
                Home
              </Link>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton>
              <Link
                to="/about"
                style={{ color: "black", textDecoration: "none" }}
                onClick={() => toggleDrawer("right", false)}>
                About Us
              </Link>
            </ListItemButton>
          </ListItem>

          {/* Product Dropdown Menu */}
          <ListItem>
            <ListItemButton onClick={handleClick}>
              <a
                type="button"
                style={{ color: "black", textDecoration: "none" }}>
                Product <KeyboardArrowDownIcon />
              </a>
            </ListItemButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{
                height: "350px",
              }}
              // PaperProps={{
              //   onClick: () => toggleDrawer("right", false), // Close drawer on any menu click
              // }}
              slotProps={{
                paper: {
                  onClick: () => toggleDrawer("right", false), // Close drawer on any menu click
                },
              }}>
              {categoryData.map((val, i) => (
                <MenuItem
                  key={i}
                  slotProps={{
                    paper: {
                      onClick: () => toggleDrawer("right", false), // Close drawer on any menu click
                    },
                  }}
                  onClick={() => {
                    handleNavigation(val); // Your navigation logic
                    toggleDrawer("right", false)();
                    handleClose();
                  }}>
                  {val.value}
                </MenuItem>
              ))}
            </Menu>
          </ListItem>

          <ListItem>
            <ListItemButton>
              <Link
                to="/gallery"
                style={{ color: "black", textDecoration: "none" }}
                onClick={() => toggleDrawer("right", false)}>
                Gallery
              </Link>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton>
              <Link
                to="/blog"
                style={{ color: "black", textDecoration: "none" }}
                onClick={() => toggleDrawer("right", false)}>
                Blog
              </Link>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton>
              <Link
                to="/contact"
                style={{ color: "black", textDecoration: "none" }}
                onClick={() => toggleDrawer("right", false)}>
                Contact
              </Link>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}

export default NavBar;
