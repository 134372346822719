import React from "react";
import Layout from "../Main Component/Layout";

function ProBiotics() {
  return (
    <>
      <Layout>
        <h1>Service</h1>
      </Layout>
    </>
  );
}

export default ProBiotics;
