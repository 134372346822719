import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";

// Component
import Home from "./Components/Home";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Career from "./Components/Career";
import Products from "./Components/Products";
import Gallary from "./Components/Gallary";
import ProBiotics from "./Components/ProBiotics";
import R_and_D from "./Components/R_and_D";
import Blog from "./Components/Blog";
import Product_Detail from "./Components/Product_Detail";
import WhatsAppButton from "./Common/WhatsAppButton";
import { useEffect } from "react";

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

function App() {
  return (
    <>
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop />
          <Routes basename={"/"}>
            <Route path={`/`} element={<Home />} />

            <Route exact path={"/about"} element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/career" element={<Career />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/gallery" element={<Gallary />} />
            <Route exact path="/probiotics" element={<ProBiotics />} />
            <Route exact path="/products/:id" element={<Products />} />
            <Route exact path="/r_and_d" element={<R_and_D />} />
            <Route exact path="/product-info/*" element={<Product_Detail />} />
          </Routes>
          <WhatsAppButton />
        </Router>
      </BreadcrumbsProvider>
    </>
  );
}

export default App;
