import React from "react";
import Layout from "../Main Component/Layout";

function R_and_D() {
  return (
    <>
      <Layout>
        <h1>Career</h1>
      </Layout>
    </>
  );
}

export default R_and_D;
